import CaretDownIcon from '@cointracker/assets/general/caret-down.svg?react';
import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import { useState, type ReactNode } from 'react';
import { navListItem } from '../MobileNavigationList.css';
import {
  caretIcon,
  collapsibleContent,
  collapsibleTrigger,
} from './CollapsibleNavItem.css';

interface CollapsibleNavItemProps {
  header: string;
  children: ReactNode;
}

export const CollapsibleNavItem = ({
  header,
  children,
}: CollapsibleNavItemProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Collapsible.Root onOpenChange={setOpen}>
      <Collapsible.CollapsibleTrigger asChild>
        <button
          className={classNames(
            navListItem({ active: isOpen }),
            collapsibleTrigger,
          )}
        >
          {header}
          <CaretDownIcon className={caretIcon({ open: isOpen })} />
        </button>
      </Collapsible.CollapsibleTrigger>

      <Collapsible.Content className={collapsibleContent}>
        {children}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
