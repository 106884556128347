import { Body, URLS } from '@cointracker/ui';
import {
  bannerContainer,
  bannerContent,
  bannerLink,
  bannerText,
} from './TaxDeadlineBanner.css';

export const TaxDeadlineBanner = () => (
  <section className={bannerContainer}>
    <div className={bannerContent}>
      <Body className={bannerText}>
        ⏰ April 15 is the US Tax Deadline{' '}
        <a className={bannerLink} href={URLS.GET_STARTED_PAGE}>
          Get started now
        </a>
      </Body>
    </div>
  </section>
);
