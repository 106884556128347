import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { NavBarLogo } from '../NavBarLogo';
import { MobileNavigationList } from './MobileNavigationList';
import { logoContainer, navBar } from './NavBar.css';
import { NavigationList } from './NavigationList';

interface NavBarProps {
  isSticky: boolean;
}
export const NavBar = ({ isSticky }: NavBarProps) => {
  const [isBurgerOpen, setBurgerOpen] = useState(false);

  return (
    // need to add the collapsible root here due to placement of the mobile container content
    <Collapsible.Root
      className=""
      open={isBurgerOpen}
      onOpenChange={setBurgerOpen}
    >
      <div className={navBar}>
        <div className={logoContainer}>
          <NavBarLogo />
        </div>
        <NavigationList />
        <MobileNavigationList isBurgerOpen={isBurgerOpen} isSticky={isSticky} />
      </div>
    </Collapsible.Root>
  );
};
