import type { ReactNode } from 'react';
import { collapseListItem } from './CollapseListItem.css';

interface CollapseListItemProps {
  to: string;
  children: ReactNode;
  isActive?: boolean;
}
export const CollapseListItem = ({
  children,
  to,
  isActive,
}: CollapseListItemProps) => {
  return (
    <a href={to} className={collapseListItem({ active: isActive })}>
      {children}
    </a>
  );
};
