import CaretDownIcon from '@cointracker/assets/general/caret-down.svg?react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { navigationMenuLink } from '../NavigationList.css';
import {
  caretIcon,
  dropdownList,
  navigationMenuContent,
  navigationMenuTrigger,
} from './DropdownNavigationMenu.css';

interface DropdownNavigationMenuProps {
  title: string;
  children?: ReactNode;
}
export const DropdownNavigationMenu = ({
  title,
  children,
}: DropdownNavigationMenuProps) => {
  return (
    <>
      <NavigationMenu.Trigger
        className={classNames(
          navigationMenuLink({ active: false }),
          navigationMenuTrigger,
        )}
      >
        {title} <CaretDownIcon className={caretIcon} aria-hidden />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className={navigationMenuContent}>
        <ul className={dropdownList}>{children}</ul>
      </NavigationMenu.Content>
    </>
  );
};
