import BurgerIcon from '@/assets/header/burger.svg?react';
import CrossIcon from '@/assets/header/cross-icon.svg?react';
import analytics from '@/common/analytics';
import {
  Box,
  Button,
  URLS,
  isURLActive,
  useGetPathname,
} from '@cointracker/ui';
import { button } from '@cointracker/ui/src/components/base/Button/Button.css';
import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getIsCoinbasePage,
  getPartnerSlug,
  isAuthenticated,
  shouldShowAuthButtonsForPartner,
} from '../../utils';
import { CollapsibleNavItem } from './CollapsibleNavItem';
import {
  CollapseListDivider,
  CollapseListItem,
} from './CollapsibleNavItem/CollapseListItem';
import {
  burgerButton,
  collapsibleContent,
  footerLink,
  mobileContainer,
  mobileNavFooter,
  mobileNavList,
  navLink,
  navListItem,
} from './MobileNavigationList.css';

interface MobileNavigationListProps {
  isBurgerOpen: boolean;
  isSticky: boolean;
}

function setViewportHeight() {
  document.documentElement.style.setProperty(
    '--viewport-height',
    `${window.innerHeight}px`,
  );
}

export const MobileNavigationList = ({
  isBurgerOpen,
  isSticky,
}: MobileNavigationListProps) => {
  const [shouldShowOpenAppButton, setShouldShowOpenAppButton] =
    useState<boolean>(false);
  const pathname = useGetPathname();
  const isCoinbasePage = getIsCoinbasePage(pathname);
  const partnerSlug = getPartnerSlug(pathname);

  const shouldShowSignUpButton =
    !partnerSlug &&
    !isURLActive(pathname, [URLS.GET_STARTED_PAGE, URLS.AUTH_LOGIN]);

  useEffect(() => {
    setShouldShowOpenAppButton(isAuthenticated());
  }, []);

  const dropdownContainer = useRef<HTMLDivElement>(null);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

  const onDropdownClose = useCallback(() => {
    document.body.style.position = 'unset';
    // Fix is only needed for sticky positionings of the dropdown
    if (isSticky) {
      const dropdownHeight = dropdownContainer?.current?.clientHeight || 0;
      const previousPosition = previousScrollPosition + dropdownHeight;
      // Scrolls back to previous scroll position, that was lost due to us changing the body to position: fixed
      window.scrollTo(0, previousPosition);
    }
  }, [dropdownContainer, isSticky, previousScrollPosition]);

  const onToggleDropdown = useCallback(() => {
    // We want to show the dropdown like a full screen page. This is a hack to disable body scrolling since
    // the dropdown is part of the DOM and not shown as a modal. The dropdown menu has full screen height and this is
    // why it works
    if (!isBurgerOpen) {
      const pageOffset = window.scrollY;
      setPreviousScrollPosition(pageOffset);
      document.body.style.position = 'fixed';
      document.body.style.left = '0';
      document.body.style.right = '0';
      // Helps with the document jumping around
      document.body.style.top = -pageOffset + 'px';
    } else {
      onDropdownClose();
    }
  }, [isBurgerOpen, onDropdownClose]);

  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  const isActive = (url: string | string[]) => isURLActive(pathname, url);

  return (
    <>
      <div className={mobileContainer}>
        <Box flexDirection="row" display="flex" alignItems="center">
          {shouldShowOpenAppButton && (
            <a href={URLS.GET_STARTED_PAGE}>
              <Button
                size="small"
                variant="ghost"
                onClick={() => analytics.track('Open app Button Clicked')}
              >
                Open app
              </Button>
            </a>
          )}
          {shouldShowSignUpButton && !shouldShowOpenAppButton && (
            <a href={URLS.GET_STARTED_PAGE}>
              {isCoinbasePage && (
                <Button
                  size="small"
                  variant="primary"
                  onClick={() =>
                    analytics.track('Sign Up Button Clicked', {
                      button_text: 'Get started',
                    })
                  }
                >
                  Get started
                </Button>
              )}
              {!isCoinbasePage && (
                <Button
                  size="small"
                  variant="primary"
                  onClick={() =>
                    analytics.track('Sign Up Button Clicked', {
                      button_text: 'Try for free',
                    })
                  }
                >
                  Try it free
                </Button>
              )}
            </a>
          )}

          <Collapsible.Trigger asChild>
            <Button
              variant="plain"
              aria-label="burger-menu-button"
              onClick={onToggleDropdown}
              className={burgerButton}
            >
              {isBurgerOpen ? <CrossIcon /> : <BurgerIcon />}
            </Button>
          </Collapsible.Trigger>
        </Box>
      </div>
      <Collapsible.Content className={collapsibleContent}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          ref={dropdownContainer}
          data-testid="collapsible-container"
        >
          <ul className={mobileNavList}>
            <li
              className={navListItem({
                active: isActive(URLS.INTEGRATIONS_PAGE),
              })}
            >
              <a href={URLS.INTEGRATIONS_PAGE} className={navLink}>
                Integrations
              </a>
            </li>
            <li
              className={navListItem({
                active: isActive(URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE),
              })}
            >
              <a
                href={URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE}
                className={navLink}
              >
                Pricing
              </a>
            </li>
            <li
              className={navListItem({
                active: isActive([
                  URLS.INTEGRATIONS_PAGE,
                  URLS.PRICES_PAGE,
                  URLS.BLOG,
                  URLS.BLOG,
                  URLS.SUPPORT,
                ]),
              })}
            >
              <CollapsibleNavItem header="Resources">
                <CollapseListItem
                  to={URLS.PRICES_PAGE}
                  isActive={isActive(URLS.PRICES_PAGE)}
                >
                  Crypto prices
                </CollapseListItem>
                <CollapseListDivider />
                <CollapseListItem
                  to={`${URLS.BLOG}/crypto-tax-guide`}
                  isActive={isActive(`${URLS.BLOG}/crypto-tax-guide`)}
                >
                  Tax guide
                </CollapseListItem>
                <CollapseListItem to={URLS.BLOG}>Blog</CollapseListItem>
                <CollapseListItem to={URLS.SUPPORT}>Support</CollapseListItem>
              </CollapsibleNavItem>
            </li>
            <li
              className={navListItem({
                active: isActive(URLS.TAX_PROS_LANDING_PAGE),
              })}
            >
              <a href={URLS.TAX_PROS_LANDING_PAGE} className={navLink}>
                Tax professionals
              </a>
            </li>
          </ul>
          <footer className={mobileNavFooter}>
            {shouldShowOpenAppButton && (
              <>
                <a
                  className={classNames(
                    footerLink,
                    button({ variant: 'ghost' }),
                  )}
                  href={URLS.GET_STARTED_PAGE}
                  onClick={() => analytics.track('Open app Button Clicked')}
                >
                  Open app
                </a>
              </>
            )}

            {(!partnerSlug || shouldShowAuthButtonsForPartner(partnerSlug)) &&
              !shouldShowOpenAppButton && (
                <>
                  <a
                    className={classNames(
                      footerLink,
                      button({ variant: 'primary' }),
                    )}
                    href={URLS.GET_STARTED_PAGE}
                    onClick={() =>
                      analytics.track('Sign Up Button Clicked', {
                        button_text: 'Try for free',
                      })
                    }
                  >
                    Try for free
                  </a>
                  <a
                    className={classNames(
                      footerLink,
                      button({ variant: 'ghost' }),
                    )}
                    href={URLS.AUTH_LOGIN}
                    onClick={() =>
                      analytics.track('Sign In Button Clicked', {
                        button_text: 'Sign in',
                      })
                    }
                  >
                    Sign in
                  </a>
                </>
              )}
          </footer>
        </Box>
      </Collapsible.Content>
    </>
  );
};
